
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.e-event-details{
  box-shadow: '3px 3px 5px 6px #ccc';
  -moz-box-shadow:    3px 3px 5px 6px #ccc;
  -webkit-box-shadow: 3px 3px 5px 6px #ccc;
  background-color: '#ffff'; 
  color:'#fff';
  padding: '30px'; 
  width: '25%';
}


.logo {
  height: 4px;
}


.k-link {
color:rgb(255, 255, 255);
}

.k-link:hover {
  color:rgb(18, 19, 51);
  }


.k-card{
  width:300px;
  height: 450px;
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  box-shadow: 0px, 0px, 3px, 1px #ccc;
}


.k-card-background  {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-color: black;
  background-position: center;
}

.k-card-background:hover {
  opacity:0.9;
  filter: brightness(50%);
  background: black;
  transform: scale(1.1);
  transition: all .5s;
  background-position: center;
  background-size: cover;
}

.k-span {
  border-bottom: 6px solid #EE8C00;
  
  border-width: 5px;
  
}

/* .k-card:hover ~.k-card-info{
  display: block;
  font-size:10px;
  min-height: 100px;
  margin-top: 0%;
  padding: 0%;
  opacity:1;
} */

.k-card-title{
  font-size:24px;
  margin: 0px;
}

@media only screen and (max-width: 1026px) {
  .k-card-info {
    display: block;
    font-size:10px;
    min-height: 5px;
    margin-top: 0%;
    padding: 5%;
    opacity:1,
  }
}

.k-card-info{
  display: block;
  font-size:10px;
  min-height: 100px;
  margin-top: 0%;
  padding: 0%;
  opacity:0;
}

.k-card-subtitle{
  font-size: 16px;
  margin: 0px;
}

/* scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
  border-radius: 15px 15px 15px 15px;
  cursor: pointer;
}

.footer {
  position: relative;
  clear: both;
    position: relative;
    height: 200px;
    margin-top: -200px;
}

